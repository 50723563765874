<template>
  <div class="recommendedPositions">
    <Header/>
    <div class="screen" style="overflow: hidden">
      <div class="screenWrap">
        <div class="inputSearch" style="width: 990px;margin-bottom: 16px">
          <img src="https://static.dasdt.cn/images/search.png" alt="">
          <div class="contentInput" style="width: 864px">
            <el-input v-model="input" placeholder="大家都在搜：JAVA" @blur="getPageJobs"
                      @keyup.enter.native="getPageJobs"></el-input>
          </div>
          <div class="submit" @click="getPageJobs">搜索</div>
        </div>
        <div class="borders"></div>
        <div class="conditionWrap">
          <div class="conditionList">
            <el-dropdown trigger="hover" @command="eventType" placement="bottom">
              <div class="item" :class="{active: type !== ''}">
                <span v-if="type === ''">求职类型</span>
                <span v-if="type === 'trainee'">实习</span>
                <span v-if="type === 'college_recruitment'">校招</span>
                <span v-if="type === 'social_recruitment'">社招</span>
                <img v-if="type === ''" style="width: 8px;height: 4px" src="https://static.dasdt.cn/images/down2.png">
                <img v-else style="width: 8px;height: 4px" src="https://static.dasdt.cn/images/down3.png">
              </div>
              <el-dropdown-menu>
                <el-dropdown-item command="trainee">实习</el-dropdown-item>
                <el-dropdown-item command="college_recruitment">校招</el-dropdown-item>
                <el-dropdown-item command="social_recruitment">社招</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown trigger="hover" @command="choice" placement="bottom">
              <div class="item" :class="{ active: educationCurrent !== '学历要求'}">
                <span>{{ educationCurrent }}</span>
                <img v-if="educationCurrent === '学历要求'" style="width: 8px;height: 4px"
                     src="https://static.dasdt.cn/images/down2.png">
                <img v-else style="width: 8px;height: 4px" src="https://static.dasdt.cn/images/down3.png">
              </div>
              <el-dropdown-menu>
                <el-dropdown-item v-for="item in educationItems" :key="item.name" :command="item.name">{{ item.name }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown trigger="hover" @command="eventExperience" placement="bottom">
              <div class="item" :class="{ active: experience !== '工作经验'}">
                <span>{{ experience }}</span>
                <img v-if="experience === '工作经验'" style="width: 8px;height: 4px" src="https://static.dasdt.cn/images/down2.png">
                <img v-else style="width: 8px;height: 4px" src="https://static.dasdt.cn/images/down3.png">
              </div>
              <el-dropdown-menu>
                <el-dropdown-item v-for="item in experienceList" :key="item.name" :command="item.name">{{ item.name }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown trigger="hover" @command="eventSalary" placement="bottom">
              <div class="item" :class="{ active: salaryTitle !== '薪资要求'}">
                <span>{{ salaryTitle }}</span>
                <!--                <span v-else style="color: #3A76FF">{{ startSalary }}k - {{ // endSalary }}k</span>-->
                <img v-if="salaryTitle === '薪资要求'" style="width: 8px;height: 4px" src="https://static.dasdt.cn/images/down2.png">
                <img v-else style="width: 8px;height: 4px" src="https://static.dasdt.cn/images/down3.png">
              </div>
              <el-dropdown-menu placement="bottom">
                <el-dropdown-item :command="index" v-for="(item, index) in salaryList" :key="item.name">
                  {{ item.name }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>

          </div>
          <div style="position: relative;z-index: 9" class="qingkong" @click="empty">清空条件</div>
        </div>
      </div>

    </div>
<!--    <div class="empty" >-->
<!--      <img src="https://static.dasdt.cn/images/empty.png">-->
<!--      <span>暂无相关岗位推荐, 为您推荐以下相似岗位</span>-->
<!--    </div>-->
    <div  v-if="params.noCate=='1'" class="tuiJianHeader" style="position: relative;z-index: 9">
      <div class="leftsss">
        <img src="https://static.dasdt.cn/images/tuijianHeader.png">
        <div class="shuoming">
          <div class="name">暂时没有合适的职位</div>
          <div class="desc">为您推荐以下相似岗位</div>
        </div>
      </div>
      <div class="rightBtn" @click="yixiangzhiwei">设置意向职位</div>
    </div>
    <div v-else style="color: #666666;font-weight: 600;font-size: 16px;width: 1200px;margin: 20px auto 0 auto;position: relative;z-index: 9">
      根据求职期望匹配推荐投递以下岗位
    </div>
    <div style="position:relative;z-index: 9">
      <div class="homeConent">
        <div class="the_content" v-if="appointmentList.length!==0">
          <isContent :appointmentList="appointmentList" :totalCount="totalCount"
                     @handleCurrentChange="handleCurrentChange"/>
        </div>
        <el-empty v-else-if="params.noCate!='1'"></el-empty>
      </div>
      <desire v-model="isShow" @wancheng="wancheng"/>
      <floatRight/>

      <div style="margin-top: 20px;" >
        <Footer/>
        <logins v-model="isShow2"/>

      </div>
    </div>



  </div>
</template>
<script>

import Header from "@/components/headers/index.vue";
import floatRight from "@/components/floatRight/vue.vue";
import isContent from "@/views/recommendedPositions/content.vue";
// import Appointment from "@/views/home/appointment.vue";
import Footer from "@/components/footer/index.vue";
import {pageSellVO} from "@/api/position";
import logins from "@/components/login/index.vue";
import desire from "@/components/desire/index.vue";

export default {
  metaInfo: {
    title: '云梯', // set a title
    meta: [   // set meta
      {
        name: '云梯, 人才, 招聘, 数字化, 神马, 服务, 平台, 企业, 推荐, 职位, 岗位',
        content: '云梯, 人才, 招聘, 数字化, 神马, 服务, 平台, 企业, 推荐, 职位, 岗位, 云梯数字化人才服务平台'
      }
    ]
  },
  components: {desire, logins, Footer, floatRight, isContent, Header},
  data() {
    return {
      // isShow2: this.$store.getters.showLogin,
      salary: '',
      isShow: false,
      totalCount: 0,
      startSalary: '',
      endSalary: '',
      inputShow: false,
      appointmentList: [],
      openDialog: false,
      city: '上海',
      positionList: [],
      educationItems: [
        {name: "不限", value: ""},
        {name: "博士", value: "博士"},
        {name: "硕士", value: "硕士"},
        {name: "本科", value: "本科"},
        {name: "大专", value: "大专"},

      ],
      experienceList: [
        {
          name: "不限",
          value: "",
        },
        {
          name: "在校生",
          value: "在校生",
        },
        {
          name: "应届生",
          value: "应届生",
        },
        {
          name: "1年以内",
          value: "1年以内",
        },
        {
          name: "1-3年",
          value: "1-3年",
        },
        {
          name: "3-5年",
          value: "3-5年",
        },
        {
          name: "5-10年",
          value: "5-10年",
        },
        {
          name: "10年以上",
          value: "10年以上",
        },
      ],
      educationCurrent: '学历要求',
      type: '',
      experience: '工作经验',
      input: '',
      salaryList: [
        {name: "不限", value: ""},
        {name: "3K以下", value: [0, 3]},
        {name: "3K-5K", value: [3, 5]},
        {name: "5K-10K", value: [5, 10]},
        {name: "10K-15K", value: [10, 15]},
        {name: "15K-20K", value: [15, 20]},
        {name: "20K-30K", value: [20, 30]},
        {name: "30K-50K", value: [30,50]},
        {name: "50K以上", value: [50, 100]},
      ],
      salaryTitle: '薪资要求',
      pagination: {
        number: 1,
        limit: 9
      },
    }
  },
  computed: {
    isShow2() {
      return this.$store.getters.showLogin
    },
    params() {
      return {
        salary: this.salary === '' ? '' : this.salary,
        searchParam: this.input,
        education: (this.educationCurrent === '学历要求' || this.educationCurrent === '不限') ? '' : this.educationCurrent,
        jobExp: (this.experience === '工作经验' || this.experience === '不限') ? '' : this.experience,
        type: this.type,
        job: this.job,
        areaSearch: this.$store.getters.area.id,
        noCate: ''
      }
    }
  },
  watch: {
    params() {
      this.getPageJobs()
    },
    // $store.state.showLogin() {},
    pagination: {
      handler: function (el) {
        console.log(el)
        this.getPageJobs()
      },
      deep: true
    },
  },
  mounted() {
    scrollTo(0, 0)
    this.getPageJobs()
  },
  methods: {
    yixiangzhiwei() {
      console.log(this.isShow2)
      if(Object.keys(this.$store.getters.userInfo).length>0) {
        this.isShow = true
      }else {
        this.$store.commit('showLogin', true)
      }
    },
    wancheng() {
      // this.params.noCate = ''

      this.empty()
    },
    handleCurrentChange(el) {
      this.pagination.number = el
    },
    eventSalary(index) {
      this.salary = this.salaryList[index].value
      this.salaryTitle = this.salaryList[index].name
    },
    blur() {
      if (this.startSalary === '') {
        this.inputShow = false
      }
    },

    // 移除多余参数
    clearBlankValue(params) {
      const obj = JSON.parse(JSON.stringify(params))
      if (obj.searchParam === '') delete this.params.searchParam
      if (obj.education === '') delete this.params.education
      if (obj.jobExp === '') delete this.params.jobExp
      if (obj.type === '') delete this.params.type
      if (obj.job === '') delete this.params.job
      if (obj.search === '') delete this.params.search
      if (obj.areaSearch === '') delete this.params.areaSearch
      if (obj.noCate === '') delete this.params.noCate
      return this.params
    },
    getTuijian() {
      this.params.noCate = 1
      // this.educationCurrent = '学历要求'
      // this.experience = '工作经验'
      // this.salaryTitle = '薪资要求'
      // this.type = ''
      this.getPageJobsTemp()
    },
    async getPageJobsTemp() {
      if (this.$route.query.info) {
        const info = JSON.parse(this.$route.query.info)
        if (info.level === 1) {
          this.params.categoryOne = info.id
        } else if (info.level === 2) {
          this.params.categoryTwo = info.id
        } else if (info.level === 3) {
          this.params.categoryThree = info.id
        }
      }
      // 职位名称
      if (this.$route.query.job) {
        this.params.job = this.$route.query.job
      }
      // 搜索框
      this.params.search = this.input
      if (this.params.salary) {
        this.params.lowSalary = this.params.salary[0]
        this.params.highSalary = this.params.salary[1]
      }

      delete this.params.salary

      this.clearBlankValue(this.params)
      // const res = await pageHotVO(this.params, {number: 1, limit: 9})
      const res = await pageSellVO(this.params, this.pagination)
      if (res.data.length === 0) {
        this.appointmentList = []
        return
      }
      res.data.forEach(item => {
        if (item.jobTagList) {
          item.tagList = []
          item.tagList = [...item.tagList, ...item.jobTagList]
        }
        if (item.customJobTag) {
          item.tagList = [...item.tagList, ...item.customJobTag]
        }
        if (item.tagList && item.tagList.length > 3) {
          item.tagList = item.tagList.slice(0, 3)
        }
        if (item.tags && item.tags.length > 3) {
          item.tags = item.tags.slice(0, 3)
        }
      })
      this.appointmentList = res.data
      this.totalCount = res.totalCount
    },
    async getPageJobs() {
      if (this.$route.query.info) {
        const info = JSON.parse(this.$route.query.info)
        if (info.level === 1) {
          this.params.categoryOne = info.id
        } else if (info.level === 2) {
          this.params.categoryTwo = info.id
        } else if (info.level === 3) {
          this.params.categoryThree = info.id
        }
      }
      // 职位名称
      if (this.$route.query.job) {
        this.params.job = this.$route.query.job
      }
      // 搜索框
      this.params.search = this.input
      if (this.params.salary) {
        this.params.lowSalary = this.params.salary[0]
        this.params.highSalary = this.params.salary[1]
      }

      delete this.params.salary

      this.clearBlankValue(this.params)
      // const res = await pageHotVO(this.params, {number: 1, limit: 9})
      const res = await pageSellVO(this.params, this.pagination)
      if (res.data.length === 0) {
        this.getTuijian()
        return
      }
      res.data.forEach(item => {
        if (item.jobTagList) {
          item.tagList = []
          item.tagList = [...item.tagList, ...item.jobTagList]
        }
        if (item.customJobTag) {
          item.tagList = [...item.tagList, ...item.customJobTag]
        }
        if (item.tagList && item.tagList.length > 3) {
          item.tagList = item.tagList.slice(0, 3)
        }
        if (item.tags && item.tags.length > 3) {
          item.tags = item.tags.slice(0, 3)
        }
      })
      this.appointmentList = res.data
      this.totalCount = res.totalCount
    },
    empty() {
      // this.educationCurrent = '学历要求'
      // this.experience = '工作经验'
      // this.type = ''
      window.location.href = "/recommendedPositions"
    },
    // 选择筛选条件
    choice(val) {
      this.educationCurrent = val
    },
    eventType(val) {
      this.type = val
    },
    eventExperience(val) {
      this.experience = val
    },
  }
}
</script>
<style lang="scss">
.el-dropdown-menu__item {
  text-align: center !important;
}

.recommendedPositions {
  //position: relative;

  background: #F5F5F6;

  overflow: hidden;
  .tuiJianHeader{
    width: 1200px;
    margin: 20px auto 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    //background: #FFFFFF;
    border-radius: 8px;
    padding: 14px 20px;
    .rightBtn{
      padding: 10px 16px;
      color: #fff;
      font-size: 14px;
      cursor: pointer;
      background: #3A76FF;
      border-radius: 4px;

    }
    .leftsss{
      display: flex;
      align-items: center;
      gap: 14px;
      img{
        width: 60px;
        height: 49.5px;
      }
      .shuoming{
        .name{
          font-size: 18px;
          color: #333333;
          font-weight: 600;
        }
        .desc{
          color: #666666;
          font-size: 16px;
          //line-height: 22px;
        }
      }
    }
  }

  .empty {
    margin-top: 30px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    img {
      width: 44px;
      height: 44px;
    }

    span {
      color: #666666;
      font-size: 16px;
    }
  }

  .salary {
    display: flex;
    align-items: center;
    gap: 10px;

    .hengxian {
      margin-left: 10px;
      margin-right: 10px;
      width: 16px;

    }

    .title {
      color: #3A3A3A;
      font-size: 14px;

    }
  }

  .homeConent {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 257px);
    //background: #F5F5F6;
    width: 100%;
    overflow: hidden;

    .the_content {
      width: 1200px;
      //z-index: 9;
      margin: 0 auto;
    }
  }

}
</style>
